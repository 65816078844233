import React from 'react';
import Layout from 'components/layout';

import HomeLogo from 'components/homelogo';

const Index = () => (
  <Layout>
    <div style={{ height: '10vh' }} />
    <HomeLogo />
  </Layout>
);

export default Index;
