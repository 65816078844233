import { Container, CenterImage } from './homelogo.css.js';
import React from 'react';
import carabiner from '../../../content/images/Wolfmich_Carabiner.gif';

const HomeLogo = () => (
  <Container>
    <CenterImage>
      <figure>
        <img style={{ maxWidth: '100%' }} src={carabiner} alt="Wolfmich"></img>
      </figure>
    </CenterImage>
  </Container>
);

export default HomeLogo;
